
import { ContactTableRow } from '@/Model/model';
import ActionButton from '@/commoncomponents/ActionButton.vue';
import ActionButtonGroup from '@/commoncomponents/ActionButtonGroup.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import ContactsDetails from '@/components/accountDetailsComponent/accountDataTabs/contacts/ContactsDetails.vue';
import ResetPasswordPopup from '@/commoncomponents/ResetPasswordPopup/ResetPasswordPopup.vue';
import { ColumnConfig, TableHeaderConfig } from '@/commoncomponents/smartTableComponents/types';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import accountParticipantsStore from '@/store/modules/accountParticipants';
import programList from '@/store/modules/programList';
import table from '@/store/modules/table';
import APP_UTILITIES from '@/utilities/commonFunctions';
import LocalStorage from '@/utilities/localStorageUtil';
import { Component, Vue, Watch } from 'vue-property-decorator';
import UIkit from 'uikit';
import {
  FamilyPortalStatus,
  GuardianListPayload,
  ContactData,
  GuardianRevokeAccessDto,
  ContactDataCvsParams,
  DeleteContactPayload
} from '@/Model/shared/types';
import {
  getListViewGuardian,
  guardianInviteStatus,
  guardianRevokeAccess,
  deleteContacts
} from '@/services/enrollment/enrollmentService';
import { ToastType } from '@/Model/toastType';
import { guardianRevokeAccessForIdentity, bulkResetPassword } from '@/services/userService/users-api';
import { getCsvInfoForGuardians } from '@/services/guardianFamilies/guardianFamiliesService';
import DeleteModalComponent from '@/components/accountDetailsComponent/accountDataTabs/modals/deleteModal/DeleteModal.vue';
@Component({
  components: {
    SmartTableContainer,
    BouncingPreloaderComponent,
    ContactsDetails,
    ActionButton,
    ActionButtonGroup,
    ResetPasswordPopup,
    DeleteModalComponent,
  }
})
export default class ContactsComponent extends Vue {
  public selectedContact: ContactData = {} as ContactData;
  private currentParticipantsPerGuardian!: Record<string, { guardianGuid: string; firstName: string; lastName: string; scholarGuid: string; type: string }[]>;
  public selectedParticipants: { firstName: string; lastName: string; scholarGuid: string }[] = [];
  public accountId: number = Number(APP_UTILITIES.getCookie('accountId'));
  public familyPortalStatusAllValuesUnique: string[] = [];
  private readonly DOUBLE_DASH: string = '--'; // This is when the family portal status is 'Invite' BE needs double dash to filter that status
  public tableHeaderConfig: TableHeaderConfig = {
    FIXED: [
      {
        name: 'First Name',
        value: 'firstName',
        action: true,
        isOpen: false,
        width: '160px',
        isSort: true,
        isSearch: false,
        isSearchList: false,
        display: true,
      },
      {
        name: 'Last Name',
        value: 'lastName',
        action: true,
        isOpen: false,
        width: '160px',
        isSort: true,
        isSearch: false,
        isSearchList: false,
        display: true,
      },
    ],
    MOVABLE: [
      {
        name: 'Participants',
        value: 'participants',
        action: true,
        isOpen: false,
        display: true,
        width: '294px',
        isSort: true,
        isSearch: false,
        isSearchList: false,
        filterKey: 'participants',
        beSerializedKey: 'Participants',
      },
      {
        name: 'Phone number',
        value: 'phone',
        action: false,
        isOpen: false,
        display: true,
        width: '130px',
        isSort: false,
        isSearch: false,
        isSearchList: false,
        filterKey: 'phone',
        beSerializedKey: 'Phone',
      },
      {
        name: 'Email',
        value: 'email',
        action: false,
        isOpen: false,
        display: true,
        width: '300px',
        isSort: false,
        isSearch: false,
        isSearchList: false,
        filterKey: 'email',
        beSerializedKey: 'Email',
      },
      {
        name: 'Family Portal Status',
        value: 'familyPortalStatus',
        action: true,
        isOpen: false,
        display: true,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'familyPortalStatus',
        beSerializedKey: 'Family Portal Status',
      },
      {
        name: 'Phone Type',
        value: 'phoneType',
        action: false,
        isOpen: false,
        display: false,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'phoneType',
        beSerializedKey: 'Phone Type',
      },
      {
        name: 'Secondary Phone',
        value: 'secondaryPhone',
        action: false,
        isOpen: false,
        display: false,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'secondaryPhone',
        beSerializedKey: 'Secondary Phone',
      },
      {
        name: 'Secondary Phone Type',
        value: 'secondaryPhoneType',
        action: false,
        isOpen: false,
        display: false,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'secondaryPhoneType',
        beSerializedKey: 'Secondary Phone Type',
      },
      {
        name: 'Address',
        value: 'address',
        action: false,
        isOpen: false,
        display: false,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'address',
        beSerializedKey: 'Address',
      },
      {
        name: 'Languages spoken at home',
        value: 'homeLanguage',
        action: false,
        isOpen: false,
        display: false,
        width: '180px',
        isSort: false,
        isSearch: true,
        isSearchList: true,
        filterKey: 'homeLanguage',
        beSerializedKey: 'Language At Home',
      },
    ]
  };
  public tableName: string = 'contacts-table';
  public items: ContactTableRow[] = [];
  public totalItems = 0;
  public initialPage: number | null = 0;
  public isSearching = false;
  public displayColumn: ColumnConfig[] = [...this.tableHeaderConfig.MOVABLE];
  public keys: string[] = ['firstName', 'lastName', 'participants', 'phone', 'email', 'familyPortalStatus', 'phoneType', 'secondaryPhone', 'secondaryPhoneType', 'address', 'homeLanguage'];
  public page = 1;
  public search = '';
  public searchCount: number = APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;
  public hoverText = '';
  public hoverStyleObj: Record<string, string> = {};
  public hoverId = 0;
  public globalSearch = '';
  public searchField = '';
  public isResizer = true;
  public isSortingSearching = true;
  public responseStatus = 0;
  sortDir = 0;
  sortField = 'firstName';
  public isSearch = false;
  public pageStatus = false;
  public localSearch = false;
  public isSearchRes = true;
  public globalSearchEmptyState = false;
  public globalSearchApiStatus = 0;
  public searchLoader = false;
  public disableDownloadButton = false;
  public noDataApiResponseCode = APP_CONST.RESPONSE_204;
  public currentPayload = APP_CONST.SCHOLAR_FAMILY_PAYLOAD;
  public displayAll = true;
  public loggedInUserId: string | null = '';
  private objScreenText = new ScreenText();
  private readonly AWAITING_INVITATION_KEY = 'Awaiting Invitation';
  private readonly ACCEPTED_KEY = 'Accepted';
  private readonly PENDING_KEY = 'Pending';
  private profilesAdminViewEnabled: boolean = false;

  public initialTotalItems: number = APP_CONST.ZERO;
  public localConst = APP_CONST;
  public isClearSearch = false;
  public isCountAvailable = false;
  public countOrName: number | string = '';
  public showFlyoutPanel = false;

  @Watch('globalSearch', { deep: true })
  watchGlobalSearch(globalSearch: string): void {
    APP_UTILITIES.setCookie('contacts_globalSearch', globalSearch, 1);
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  get contactsPayload() {
    return accountParticipantsStore.guardianInfoPayload;
  }

  get selectedCount(): number {
    return this.selectedItems.length;
  }

  get selectedItems(): ContactTableRow[] {
    return table.allDataSelected.filter((item: ContactTableRow) => item.selected);
  }

  get shouldShowActions(): boolean {
    return this.selectedCount > 0;
  }

  get contactsResizeArray() {
    return accountParticipantsStore.contactsResizeArray;
  }

  get inviteScheduleDate() {
    return programList.scheduleDateInvitation;
  }

  get accountIdFromUserRoleOrHighestRole() {
    let accountId;
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    if (userRoles && Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN) {

      const role = APP_UTILITIES.getCookie('user_role');
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    }
    else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }
    return accountId;
  }

  get programIds(): string[] {
    const programsData = JSON.parse(String(APP_UTILITIES.getCookie('all_programs_associated_to_program_admin')));
    const programsId: string[] = [];
    programsData.forEach((program: any) => {
      if ((program.roleId == APP_CONST.ROLE_TYPE_PROGRAM_ADMIN)) {
        programsId.push(program.programId);
      }
    });
    return programsId;
  }

  /**
   * It indicates if the app should hide the invite action from ghost bar, based on if every selected item has an email.
  */
  get showInviteAction(): boolean {
    return this.selectedItems.every((item) => item.email);
  }

  /**
   * It indicates if the app should hide the revoke access action from ghost bar, based on if the user has never been invited before.
  */
  get showRevokeAccessAction(): boolean {
    return !this.selectedItems.some(item => item.familyPortalStatus === this.AWAITING_INVITATION_KEY);
  }

  /**
   * Determines whether the delete action should be shown.
   * @returns {boolean} True if the delete action should be shown, false otherwise.
   */
  get showDeleteAction(): boolean {
    return this.selectedItems.length > 0;
  }

  /**
   * Determines whether the reset password action should be shown.
   *
   * @returns {boolean} True if the reset password action should be shown, false otherwise.
   */
  get showResetPasswordAction(): boolean {
    return this.selectedItems.every(item =>
      item.familyPortalStatus === this.ACCEPTED_KEY || item.familyPortalStatus === this.PENDING_KEY
    );
  }

  /**
   * It retrieves the guardianId from the selected items.
  */
  get guardianIdFromSelectedItems() {
    return this.selectedItems.map((item) => item.guardianGuid);
  }

  get accountDetails() {
    return programList.accountInfo;
  }

  get addEditViewModeValue() {
    return programList.addEditViewModeValue;
  }

  get searchContact() {
    return accountParticipantsStore.searchContacts;
  }

  get isLoaderVisible() {
    return (this.initialTotalItems >= this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO);
  }

  get resetPasswordToLabel(): string {
    const selectedContactsCount = this.selectedItems.length;
    return selectedContactsCount > 1
      ? this.getScreenText('CONTACTS_PERIOD')
      : this.getScreenText('CONTACT_PERIOD');
  }

  get deleteActionCopy(): object {
    const isMultiple = this.selectedCount > 1;
    const contactLabel = this.objScreenText.getScreenText(isMultiple
      ? 'CONTACTS'
      : 'CONTACT');

    return {
      actionLabel: this.objScreenText.getScreenTextWithParams('DELETE_USER_ACTION', contactLabel),
      countLabel: isMultiple
        ? this.selectedCount
        : 0,
      disclaimerMessage1: this.objScreenText.getScreenText('DELETE_USER_DISCLAIMER1'),
      disclaimerMessage2: this.objScreenText.getScreenText('DELETE_USER_DISCLAIMER2'),
      disclaimerMessage3: this.objScreenText.getScreenTextWithParams('DELETE_USER_DISCLAIMER3', contactLabel)
    };
  }

  beforeMount(): void {
    const storedAccountId: string | null = APP_UTILITIES.getCookie('accountId');
    const storedSearchCount = LocalStorage.getFromLocalStorage(`${this.tableName}_searchCount`);

    this.searchCount = storedSearchCount
      ? Number(storedSearchCount)
      : APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;

    accountParticipantsStore.mutateContactsPayload(JSON.parse(JSON.stringify(APP_CONST.SCHOLAR_FAMILY_PAYLOAD)));
    accountParticipantsStore.mutateContactsPayload({
      ...accountParticipantsStore.contactsPayload,
      accountId: parseInt(storedAccountId || '0'),
      search: '',
      count: this.searchCount
    });
  }

  async created(): Promise<void> {
    this.profilesAdminViewEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.profilesAdminView);

    localStorage.removeItem('manage_families_scholar_details');
    this.familyPortalStatusAllValuesUnique = [
      this.getScreenText('FAMILY_PORTAL_STATUS_AWAITING'),
      this.getScreenText('FAMILY_PORTAL_STATUS_ACCEPTED'),
      this.getScreenText('FAMILY_PORTAL_STATUS_EXPIRED'),
      this.getScreenText('FAMILY_PORTAL_STATUS_PENDING'),
      this.getScreenText('FAMILY_PORTAL_STATUS_DEACTIVATED'),
    ];
    const globalSearch = APP_UTILITIES.getCookie('contacts_globalSearch');

    this.globalSearch = globalSearch === 'null' || !globalSearch
      ? ''
      : String(globalSearch);
    accountParticipantsStore.mutateCurrentContact({ ...this.searchContact, firstName: '', lastName: '', participant: '', phone: '', email: '' });
  }

  mounted(): void {
    this.handleContactsTable();
    this.loggedInUserId = APP_UTILITIES.getCookie('user_id');
  }

  handleContactsTable(): void {
    const authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    this.search = this.contactsPayload && this.contactsPayload.search
      ? this.contactsPayload.search
      : this.search;
    this.searchField = this.contactsPayload && this.contactsPayload.searchField
      ? this.contactsPayload.searchField
      : this.searchField;
    this.sortDir = this.contactsPayload && this.contactsPayload.sortDir
      ? this.contactsPayload.sortDir
      : this.sortDir;
    this.sortField = this.contactsPayload && this.contactsPayload.sortField
      ? this.contactsPayload.sortField
      : this.sortField;
    this.manageList();
    let accountId: string | null = null;
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    if (userRoles && Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN) {
      const role = APP_UTILITIES.getCookie('user_role');
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    }
    else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }
    if (APP_UTILITIES.getFilteredColumns(`${authKey}-ContactsSettingsData`)) {
      let contactSettingsData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-ContactsSettingsData`);
      contactSettingsData = JSON.parse(contactSettingsData);
      if (contactSettingsData.hasOwnProperty([accountId])) {
        Object.keys(contactSettingsData).forEach(key => {
          if (String(key) === String(accountId)) {
            this.displayColumn = contactSettingsData[key];
          }
        });
      }
    }
    this.isClearSearch = false;
  }

  private mapFieldsBeforeFiltering(value: string): string {
    return value === this.AWAITING_INVITATION_KEY
      ? this.DOUBLE_DASH
      : value;
  }

  async manageList() {
    let accountId;
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    if (userRoles && Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN) {

      const role = APP_UTILITIES.getCookie('user_role');
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    }
    else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }
    this.isSearchRes = false;
    const payload = { ...APP_CONST.SCHOLAR_FAMILY_PAYLOAD, familyPortalStatus: FamilyPortalStatus.All } as any;
    if (!this.globalSearch.trim().length || this.globalSearch.trim().length >= 3) {
      payload.search = this.mapFieldsBeforeFiltering(this.search);
      payload.accountId = Number(accountId);
      payload.page = this.globalSearch
        ? 1
        : this.page;
      payload.count = this.searchCount;
      payload.sortField = this.sortField
        ? this.sortField
        : 'firstName';
      payload.sortDir = this.sortDir
        ? this.sortDir
        : 1;
      payload.globalSearch = this.globalSearch;
      payload.searchField = this.searchField;
      const programsData = JSON.parse(String(APP_UTILITIES.getCookie('all_programs_associated_to_program_admin')));
      const programsId: string[] = [];
      programsData.forEach((program: any) => {
        if ((program.roleId == APP_CONST.ROLE_TYPE_PROGRAM_ADMIN)) {
          programsId.push(program.programId);
        }
      });
      payload.ProgramIdValues = Number(userRoles) === Number(APP_CONST.ROLE_TYPE_PROGRAM_ADMIN)
        ? Array(programsId).join(',')
        : '';
      this.currentPayload = payload;
      if (this.searchField.length) {
        this.localSearch = true;
      }
      else if (!this.searchField.length && this.responseStatus != 204 && this.globalSearch) {
        this.localSearch = false;
      }
      this.responseStatus = 0;
      const contactData: any = await accountParticipantsStore.fetchGuardianList(payload as GuardianListPayload);

      this.pageStatus = true;
      if (this.globalSearch.trim().length >= 3) {
        this.globalSearchApiStatus = contactData.status;
      }
      if (contactData.status === 200) {

        this.responseStatus = contactData.status;

        this.items = contactData.data.guardianData.map((guardian: any) => {
          //TODO: Add the logic here for identifying if its from Guardian, Emergency Contact and so on.
          this.currentParticipantsPerGuardian = {
            ...this.currentParticipantsPerGuardian,
            [guardian.email]: guardian.scholarData && guardian.scholarData.length
              ? guardian.scholarData.map((scholar: any) => ({ firstName: scholar.firstName, lastName: scholar.lastName, scholarGuid: scholar.scholarGuid, type: 'Guardian' }))
              : null,
          };
          return {
            ...guardian,
            initialAddress: guardian.address,
            secondaryPhone: guardian.secondaryPhone
              ? APP_UTILITIES.formatNumber(guardian.secondaryPhone)
              : this.DOUBLE_DASH,
            address: Object.keys(guardian.address).length > 5
              ? APP_UTILITIES.formatAddress(guardian)
              : this.DOUBLE_DASH,
            participants: guardian.scholarData && guardian.scholarData.length
              ? `${guardian.scholarData[0].firstName} ${guardian.scholarData[0].lastName} ${guardian.scholarData.length > 1
                ? `+${guardian.scholarData.length - 1}`
                : ''}`
              : this.DOUBLE_DASH,
            phone: APP_UTILITIES.formatNumber(guardian.phone),
            email: guardian.email,
            familyPortalStatus: FamilyPortalStatus[guardian.familyPortalStatus] === String(FamilyPortalStatus[FamilyPortalStatus.Invite])
              ? this.getScreenText('FAMILY_PORTAL_STATUS_AWAITING')
              : FamilyPortalStatus[guardian.familyPortalStatus],
            selected: false,
            rowKey: guardian.guardianGuid,
          };
        });

        this.selectAllCheck();
        this.totalItems = contactData.data.count;
        this.isSearching = false;
      }
      else if (contactData.status === this.noDataApiResponseCode) {
        this.items = [];
        this.totalItems = 0;
        this.responseStatus = contactData.status;
        if (this.globalSearch.length && this.globalSearch.length >= 3) {
          this.globalSearchEmptyState = true;
        }
      }
      else {
        this.items = [];
        this.totalItems = 0;
        this.isSearching = payload.search
          ? true
          : false;
      }
    }
    if (this.initialTotalItems === APP_CONST.ZERO) {
      this.initialTotalItems = this.totalItems;
    }
  }

  selectAllCheck(): void {
    this.items && this.items.forEach((contact) => {
      table.allDataSelected && table.allDataSelected.forEach((elementChecked: ContactTableRow) => {
        if (elementChecked.guardianGuid === contact.guardianGuid && elementChecked.firstName === contact.firstName) {
          contact.selected = elementChecked.selected;
        }
      });
    });
    if (table.allSelect) {
      table.mutateSelectAll(true);

      this.items && this.items.forEach((contact) => {
        contact.selected = true;
      });
      this.items && this.items.forEach((contact) => {
        table.unSelectData && table.unSelectData.length && table.unSelectData.forEach((elementChecked: ContactTableRow) => {
          if (elementChecked.guardianGuid === contact.guardianGuid && elementChecked.firstName === contact.firstName) {
            contact.selected = false;
          }
        });
      });
    }
  }

  clearSelection(): void {
    table.mutateSelectAll(false);
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
    table.mutateUnSelectedData([]);
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
    this.items && this.items.forEach((element) => {
      element.selected = false;
    });
  }

  setPage(page: number): void {
    accountParticipantsStore.mutateContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
    this.page = page;
    this.manageList();
  }

  callHover(value: string, event: any, id?: number): void {
    this.hoverText = value;
    if (id) {
      this.hoverId = id;
    }
    const boundBox = event && event.target && event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.hoverStyleObj = {
      position: 'fixed',
      top: `${coordY + 45}px`,
      left: `${(coordX + 15)}px`
    };
  }

  callHoverOut(): void {
    this.hoverText = '';
    this.hoverId = 0;
  }

  clearSearch(): void {
    this.isClearSearch = APP_CONST.TRUE;
    this.searchLoader = false;
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.globalSearch = '';
    this.isSearching = false;
    this.manageList();
  }

  resetPage(): void {
    this.page = APP_CONST.ONE;
    accountParticipantsStore.mutateContactsPayload({ ...this.contactsPayload, page: APP_CONST.ONE });
  }

  removeChecked(): void {
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
  }

  filterColumns(displayColumn: ColumnConfig[]): void {
    let isSelected = false;
    displayColumn && displayColumn.length && displayColumn.forEach((column) => {
      if (column.display) {
        isSelected = true;
      }
    });
    this.displayAll = isSelected;
    this.clearAllColumns(this.displayAll);
    accountParticipantsStore.mutateContactsSettingsData(displayColumn);
    accountParticipantsStore.mutateContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
  }

  clearAllColumns(displayAll: boolean): void {
    const isSearchFieldFixed = this.tableHeaderConfig.FIXED.map((e) => {
      return e.value;
    }).indexOf(this.searchField);

    const isSortFieldFixed = this.tableHeaderConfig.FIXED.map((e) => {
      return e.value;
    }).indexOf(this.sortField);
    if (!displayAll && isSearchFieldFixed === APP_CONST.MINUS_ONE) {
      this.search = this.searchField === APP_CONST.FIRST_NAME || this.searchField === APP_CONST.LAST_NAME
        ? this.search
        : '';
      this.searchField = this.searchField === APP_CONST.FIRST_NAME || this.searchField === APP_CONST.LAST_NAME
        ? this.searchField
        : '';
      accountParticipantsStore.mutateContactsSearchObj({ ...this.searchContact });
      this.manageList();
    }
    if (!displayAll && isSortFieldFixed === APP_CONST.MINUS_ONE) {
      this.sortDir = this.sortField === APP_CONST.FIRST_NAME || this.sortField === APP_CONST.LAST_NAME
        ? this.sortDir
        : APP_CONST.ZERO;
      this.sortField = this.sortField === APP_CONST.FIRST_NAME || this.sortField === APP_CONST.LAST_NAME
        ? this.sortField
        : '';
      accountParticipantsStore.mutateSortContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
      accountParticipantsStore.mutateContactsSearchObj({ ...this.searchContact });
      this.manageList();
    }
  }

  filterDisplayColumn(column: ColumnConfig): void {
    if (!column.display) {
      if (this.contactsPayload && (column.value === this.contactsPayload.searchField)) {
        this.search = '';
        this.searchField = '';
        const columnName = column.value;
        accountParticipantsStore.mutateContactsSearchObj({ ...this.searchContact, [columnName]: '' });
        this.manageList();
      }
      if (column.value === this.contactsPayload.sortField) {
        this.sortDir = 0;
        this.sortField = '';
        accountParticipantsStore.mutateSortContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
        this.manageList();
      }
    }
  }

  formatDate(date: string): string {
    return APP_UTILITIES.formatShortDate(date);
  }

  sortContacts(sortObj: { sortField: string; sortDirection: number; customField: string }): void {
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.sortField = sortObj.sortField;
    this.sortDir = sortObj.sortDirection;
    this.page = 1;
    accountParticipantsStore.mutateSortContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
    accountParticipantsStore.mutateContactsPayload({ ...this.contactsPayload, sortField: this.sortField, sortDir: this.sortDir });
    this.globalSearch = this.globalSearch
      ? this.globalSearch
      : '';
    this.manageList();
  }

  searchContactsData(search: { searchField: string; isExactMatch: boolean }): void {
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.page = 1;
    this.searchField = search.searchField;
    this.search = this.searchContact[search.searchField]
      ? this.searchContact[search.searchField]
      : '';
    this.globalSearch = this.globalSearch
      ? this.globalSearch
      : '';
    if (search.searchField) {
      accountParticipantsStore.mutateGuardianSearchObj({
        ...this.searchContact, [search.searchField]: (this.searchContact && this.searchContact[search.searchField]
          ? this.searchContact[search.searchField]
          : ''), isExactMatch: search.isExactMatch
      });
      accountParticipantsStore.mutateContactsPayload({
        ...this.contactsPayload, searchField: this.searchField, sortDir: this.sortDir, isExactMatch: search.searchField
          ? search.isExactMatch
          : false
      });
    }
    else {
      accountParticipantsStore.mutateGuardianSearchObj({ ...this.searchContact });
      accountParticipantsStore.mutateContactsPayload({
        ...this.contactsPayload, searchField: this.searchField, sortDir: this.sortDir
      });
    }
    if (this.searchField.length) {
      this.localSearch = true;
    }
    this.clearSelection();
    this.manageList();
  }

  presistResize(resize: { column: any; maxWidth: any; minWidth: any }): void {
    accountParticipantsStore.mutateResizeDataContacts(resize);
  }

  callApionBackSpace(): void {
    if (this.globalSearch === '') {
      this.globalSearchApiStatus = 204;
      this.searchLoader = false;
      this.manageList();
      accountParticipantsStore.mutateContactsPayload(this.contactsPayload);
    }
  }

  searchGlobalData(): void {
    if (this.globalSearch.length >= 3) {
      this.searchLoader = true;
      this.manageList();
    }
  }

  destroyed(): void {
    table.mutateSelectAll(false);
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
    table.mutateUnSelectedData([]);
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
  }

  handleRecordClick(event: ContactData): void {
    const { initialAddress } = event;
    this.selectedContact = event;
    this.showFlyoutPanel = true;
    this.selectedParticipants = this.currentParticipantsPerGuardian[event.email] || [];

    // TODO: Once Profiles is on prod and stable delete this whole block
    if (!this.profilesAdminViewEnabled) {
      const payloadForEnrollment = {
        isType: 'guardian', scholarCurrentIndex: 0,
        scholarData: [...(event && event.hasOwnProperty('scholarData')
          ? event.scholarData.map(item => ({ ...item, scholarId: item.scholarGuid }))
          : [])]
      };
      APP_UTILITIES.setCookie('manage_families_scholar_details', JSON.stringify(payloadForEnrollment), 1);
      APP_UTILITIES.setCookie('selected_guardian_details', JSON.stringify({...event, address: initialAddress}), 1);
      APP_UTILITIES.setCookie('isRedirectFromAdmin', JSON.stringify(true), 1);
    }
  }

  /**
   * Sets the message for success toast after sending invitations to selected contacts and also clears the selection.
  */
  async inviteSelectedContactsToFamilyPortal() {
    const singularPlural = this.guardianIdFromSelectedItems.length > 1
      ? 'PLURAL'
      : 'SINGULAR';
    const accountId = APP_UTILITIES.getCookie('accountId');
    const response = await getListViewGuardian(this.guardianIdFromSelectedItems, Number(accountId));
    await this.guardianInviteStatusProcess();
    if (response.status === APP_CONST.RESPONSE_200) {
      APP_UTILITIES.showToastMessage(
        this.getScreenText(`CONTACTS_INVITATION_SEND_SUCCESS_${singularPlural}`),
        ToastType.Success
      );
      this.manageList();
      this.clearSelection();
    }
  }

  /**
   * Checks the status of the guardian invite process and waits until the process is completed by implementing recursion
  */
  async guardianInviteStatusProcess() {
    const accountId = APP_UTILITIES.getCookie('accountId');
    const { data: { isJobInProgress = false } } = await guardianInviteStatus(Number(accountId));
    if (isJobInProgress) {
      await this.guardianInviteStatusProcess();
    }
  }

  /**
   * Opens the reset password modal for the selected contacts.
   *
   * @returns {void}
   */
  openResetPasswordModalForSelectedContacts(): void {
    if (UIkit.modal('#reset-password-popup')) {
      UIkit.modal('#reset-password-popup').show();
    }
  }

  /**
   * Resets the password for a contact.
   *
   * @param {string} password - The new password for the contact.
   */
  async onResetContactPassword(password: string): Promise<void> {
    const selectedIds = this.selectedItems.map(({ userId }) => userId);
    const payload = { selectedIds, password };
    const progressBar: any = this.$refs.resetPassword;
    progressBar.isValid = false;

    try {
      const res = await bulkResetPassword(payload);

      if (res.status === APP_CONST.RESPONSE_200) {
        if (progressBar) {
          progressBar.apiResponseCode = APP_CONST.RESPONSE_200;
          progressBar.isValid = true;
        }

        if (!res.data.data.fail || res.data.data.fail.length === 0) {
          APP_UTILITIES.showToastMessage(
            this.getScreenText('PASSWORD_SUCCESSFULLY_RESET'),
            ToastType.Success
          );
        }
        else {
          APP_UTILITIES.showToastMessage(
            this.getScreenText('PASSWORD_RESET_FAILED'),
            ToastType.Error
          );
        }

        this.manageList();
        this.clearSelection();
      }
      else if (res.status === APP_CONST.RESPONSE_400 && progressBar) {
        progressBar.apiResponseCode = APP_CONST.RESPONSE_400;
      }
      else if (res.status === APP_CONST.RESPONSE_500 && progressBar) {
        progressBar.apiResponseCode = APP_CONST.RESPONSE_500;
      }
    }
    catch (err) {
      if (progressBar) {
        progressBar.apiResponseCode = APP_CONST.RESPONSE_500;
      }
    }
  }

  async revokeAccessFromSelectedContacts(): Promise<void> {
    const revokeAccess: GuardianRevokeAccessDto[] = this.selectedItems.map(({ guardianGuid, userId }) => ({ guardianId: guardianGuid, userId: Number(userId), status: FamilyPortalStatus.Deactivated }));
    const response = await guardianRevokeAccess(revokeAccess);
    const identityResponse = await guardianRevokeAccessForIdentity(revokeAccess.filter(item => item.userId > 0));
    if (response.status === APP_CONST.RESPONSE_200 && identityResponse.status === APP_CONST.RESPONSE_200) {
      APP_UTILITIES.showToastMessage(
        this.getScreenText('CONTACTS_ACCESS_REVOKED'),
        ToastType.Success
      );
      this.manageList();
      this.clearSelection();
    }
  }

  deleteSelectedContacts(): void {
    if (UIkit.modal('#delete-popup')) {
      UIkit.modal('#delete-popup').show();
    }
  }

  /**
   * Handles the deletion of contacts.
   */
  async handleDeleteContact(): Promise<void> {
    const payload: DeleteContactPayload = {
      accountId: this.accountIdFromUserRoleOrHighestRole,
      guardiansIds: this.selectedItems.map(({ guardianGuid, userId }) => ({ guardianGuid, userId: Number(userId) }))
    };

    try {
      const res = await deleteContacts(payload);

      if (res.status === APP_CONST.RESPONSE_200) {
        APP_UTILITIES.showToastMessage(
          this.getScreenText('SUCCESSFULLY_DELETED'),
          ToastType.Success
        );

        this.manageList();
        this.clearSelection();
      }
      else {
        APP_UTILITIES.showToastMessage(
          this.getScreenText('FAILED_TO_DELETE'),
          ToastType.Error
        );
      }
    }
    catch (err) {
      APP_UTILITIES.showToastMessage(
        this.getScreenText('FAILED_TO_DELETE'),
        ToastType.Error
      );
    }
  }

  async downloadCSV() {
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    const columnsToHide = this.displayColumn.filter(column => !column.display).map(column => column.beSerializedKey).join(',');

    const downloadPayload: ContactDataCvsParams = {
      AccountId: this.accountIdFromUserRoleOrHighestRole,
      Id: 0,
      Active: 0,
      columnsToHide,
      ProgramIdValues: Number(userRoles) === Number(APP_CONST.ROLE_TYPE_PROGRAM_ADMIN)
        ? Array(this.programIds).join(',')
        : '',
      SessionIdValues: '',
      GlobalSearch: this.globalSearch,
      IsBulk: false,
      IsReport: false,
      Status: FamilyPortalStatus.All,
      SearchField: this.searchField,
      Search: this.mapFieldsBeforeFiltering(this.search),
      IsExactMatch: false,
      CustomField: '',
      RoleType: 0,
      SortField: this.sortField,
      SortDir: this.sortDir,
      applyRoleFilter: true,
    };
    const { data, fileName} = await getCsvInfoForGuardians(downloadPayload);
    APP_UTILITIES.downloadCsv(data, fileName);
  }

  /**
   * Handles the change of the number of items per page.
   *
   * @param {number} numberOfItems - The number of items per page.
   */
  itemsPerPageChanged(numberOfItems: number) {
    this.searchCount = numberOfItems;
    this.page = 1;
    accountParticipantsStore.mutateContactsPayload({ ...this.contactsPayload, count: numberOfItems });
    LocalStorage.saveInLocalStorage(`${this.tableName}_searchCount`, numberOfItems);
    this.manageList();
  }
}
